<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="9">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-card class="white shadow1 rounded">
                            <v-toolbar color="white" :elevation="0">
                                <v-toolbar-title class="subtitle-1">Customer Entry</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="py-0">
                                <v-form ref="customerForm" class="custom-form" @submit.prevent="saveCustomer">
                                    <v-row>
                                        <v-col cols="6" class="pa-2">
                                            <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Customer Type</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-radio-group v-model="customer.type" row>
                                                        <v-radio label="Regular" value="Retail"></v-radio>
                                                        <v-radio label="Dealer" value="Wholesale"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>

                                            <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Customer Id</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model="customer.code" :rules="[v => !!v]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.name" :rules="[v => !!v]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pb-1" v-if="customer.type == 'Retail'">
                                                <v-col cols="4" class="py-1">Father Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.father_name" :rules="[v => !!v]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Company Name</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.org_name"></v-text-field>
                                                </v-col>
                                            </v-row> -->
                                            <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Area</v-col>
                                                <v-col cols="7" class="py-0">
                                                    <v-combobox dense outlined hide-details @focus="$store.dispatch('area/getAreas')" :items="$store.getters['area/areas']" :loading="$store.getters['area/loadingAreas']" item-text="name" item-value="id" v-model="area">
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1" class="py-0 pa-0" style="margin-left:-12px">
                                                    <area-dialog :icon="true" ref="areaDialog"></area-dialog>
                                                </v-col>
                                            </v-row>

                                            <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Address</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.address" :rules="[v => !!v]"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="customer.type == 'Retail'">
                                                <v-col cols="4" class="py-1">NID No</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.nid"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="6" class="pa-2">
                                            <v-row>
                                                <v-col cols="4" class="py-1">Mobile</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.phone" :rules="[v => !!v, () => validateMobile(customer.phone)]" type="number"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">License NO.</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.license_no" type="text"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">Issued By</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.license_issued_by" type="text"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="customer.type == 'Retail'">
                                                <v-col cols="4" class="py-1">Issued Date</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.license_issued_date" type="date"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1">
                                                <v-col cols="4" class="py-1">Renewal Date</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.license_renual_date" type="date"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-1" v-if="customer.type == 'Retail'">
                                                <v-col cols="4" class="py-1">Verified By</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model.trim="customer.license_verified_by" type="text"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <!-- <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Office Phone</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="customer.org_phone"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->

                                            <!-- <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Email</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="customer.email"
                                                        type="email"
                                                        :rules="[() => validateEmail(customer.email)]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->

                                            <!-- <v-row class="pb-1">
                                                <v-col cols="4" class="py-1">Credit Limit</v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="customer.credit_limit"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row> -->

                                            <v-row class="py-1">
                                                <v-col cols="4" class=""></v-col>
                                                <v-col cols="8" class="py-0">
                                                    <v-row no-gutters>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" block class="text_bg_fave">Save</v-btn>
                                                        </v-col>
                                                        <v-col cols="6" class="pl-1">
                                                            <v-btn dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-data-table class="custom-data-table white shadow1 rounded py-4 px-3" :headers="customerHeaders" :items="$store.getters['customer/customers']" :loading="$store.getters['product/loadingProducts']" :search="searchCustomer" loading-text="Loading... Please wait" @click:row="showDetails">
                            <template v-slot:top>
                                <v-toolbar dense color="white" :elevation="0">
                                    <v-toolbar-title class="subtitle-2">Customer List</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-form class="custom-form">
                                        <v-text-field outlined dense hide-details placeholder="Search Customer" append-icon="mdi-magnify" style="width:300px;" v-model="searchCustomer">
                                        </v-text-field>
                                    </v-form>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="3">
                <v-card dark color="white" class="mb-3 shadow1">
                    <v-card-text class="text-center pa-2">
                        <v-row class="pt-0">
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="frontImagePreview" :src="frontImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="frontImage" @change="onChangeFrontImage()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Front Image</h3>
                                </v-col>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="backImagePreview" :src="backImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="backImage" @change="onChangeBackImage()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Back Img</h3>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row class="text-center pt-0">
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="recieptImagePreview" :src="recieptImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="recieptImage" @change="onChangeRecieptImage()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Reciept Img</h3>
                                </v-col>
                            </v-col>

                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="permissionImagePreview" :src="permissionImagePreview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="permissionImage" @change="onChangePermissionImage()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Permission</h3>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-row class="text-center pt-0">
                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="image5Preview" :src="image5Preview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="image5" @change="onChangeImage5()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Customer img</h3>
                                </v-col>
                            </v-col>

                            <v-col cols="6" class="pb-0">
                                <div class="position-relative">
                                    <v-avatar tile color="blue lighten-4" size="110">
                                        <v-img v-if="image6Preview" :src="image6Preview"></v-img>
                                        <v-icon v-else dark>mdi-image</v-icon>
                                    </v-avatar>
                                    <v-file-input class="doc-image-input" hide-input truncate-length="15" accept=".png, .jpg" v-model="image6" @change="onChangeImage6()"></v-file-input>
                                </div>
                                <v-col cols="12" class="pb-0">
                                    <h3 class="doc-subtitle">Arms img</h3>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card tile class="mx-auto white shadow_f rounded" style="width: 100%" :elevation="0">
                    <v-card dark tile flat color="#26ab68ad" class="carv_img_wrap rounded">
                        <v-card-text class="text-center">
                            <!-- <v-avatar color="blue lighten-2" size="50" class="my-2">
                                <span class="white--text headline">C</span>
                            </v-avatar>
                            <h2 class="caption">{{ selectedCustomer.code }}</h2>
                            <h1 class="subtitle-2">{{ selectedCustomer.name }}</h1>
                            <div v-if="userType == 'super_admin' || userType == 'admin'">
                                <v-btn icon color="white" @click="editCustomer(selectedCustomer)">
                                    <v-icon>mdi-account-edit</v-icon>
                                </v-btn>
                                <v-btn icon color="white" @click="customerId = selectedCustomer.id;$refs.confirmDialog.dialog = true">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            -->
                            <img class="carv_img" :src="carv" alt="">
                            <template v-if="selectedCustomer">
                                <img class="uavatar" :src="`https://ui-avatars.com/api/?name=${selectedCustomer.name}&background=26ab68ad&color=fff`" alt="alt">
                            </template>
                        </v-card-text>
                    </v-card>

                    <div class="actions_btns">
                        <h2 class="subtitle-1">{{ selectedCustomer.code }}</h2>
                        <h1 class="subtitle-1">{{ selectedCustomer.name }}</h1>
                        <div v-if="userType == 'super_admin' || userType == 'admin'">
                            <v-btn icon color="black" @click="editCustomer(selectedCustomer)">
                                <v-icon>mdi-account-edit</v-icon>
                            </v-btn>
                            <v-btn icon color="black" @click="customerId = selectedCustomer.id; $refs.confirmDialog.dialog = true">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-list dense class="info_list">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-cellphone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                        <v-divider inset></v-divider>

                        <!-- <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-phone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.org_phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider> -->

                        <!-- <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-email</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->

                        <!-- <v-divider inset></v-divider> -->

                        <!-- <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-credit-card</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.credit_limit }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->

                        <!-- <v-divider inset></v-divider> -->

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-office-building</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.org_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-map-marker</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedCustomer.address }}</v-list-item-title>
                                <v-list-item-subtitle v-if="selectedCustomer.area">{{ selectedCustomer.area.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedCustomer.front_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedCustomer.front_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Front Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedCustomer.back_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedCustomer.back_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Front Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedCustomer.reciept_img" @click.prevent="()=> {
                                        downloadable = true;
                                        downloadableLink = selectedCustomer.reciept_img;
                                    }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Receipt Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="selectedCustomer.permission_img" @click.prevent="()=> {
                                downloadable = true;
                                downloadableLink = selectedCustomer.permission_img;
                            }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Permission Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="selectedCustomer.image5" @click.prevent="()=> {
                                downloadable = true;
                                downloadableLink = selectedCustomer.image5;
                            }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Customer Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="selectedCustomer.image6" @click.prevent="()=> {
                                downloadable = true;
                                downloadableLink = selectedCustomer.image6;
                            }">
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-download</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    View Arms Image
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <div class="text-center">
            <v-dialog v-model="downloadable" width="auto">
                <v-card>
                    <v-card-text>
                        <img :src="downloadableLink" width="100%" />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" block @click="downloadable=null">Close Dialog</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <delete-confirm ref="confirmDialog" @confirm="deleteCustomer"></delete-confirm>
    </v-container>
</template>

<script>
/*
"id": 2,
  "type": "",
  "code": "C00002",
  "name": "Auto King",
  "father_name": "autokingut",
  "phone": "01895222335",
  "nid": null,
  "license_no": "015451545",
  "license_issued_by": "mr. x",
  "license_issued_date": "2022-11-13",
  "license_renual_date": "2022-11-13",
  "license_verified_by": "mr. y",
  "area_id": 3,
  "address": "Uttara, Dhaka, Bangladesh",
  "org_name": "Auto king Uttara",
  "org_phone": "0255656",
  "credit_limit": "100000.00",
  "front_img": null,
  "back_img": null,
  "reciept_img": null,
  "permission_img": null,
  "added_by": 1,
  "updated_by": null,
  "branch_id": 1,
  "deleted_at": null,
  "created_at": "2022-11-13T17:31:18.000000Z",
  "updated_at": "2022-11-13T17:31:18.000000Z",
  "area": {
    "id": 3,
    "name": "Uttara",
    "added_by": 1,
    "updated_by": null,
    "deleted_at": null,
    "created_at": "2022-11-13T17:30:39.000000Z",
    "updated_at": "2022-11-13T17:30:39.000000Z"
  },

*/
import areaDialog from '../../components/area-dialog.component'
import utilities from '../../mixins/utility.mixin'
import confirmDialog from '../../components/confirm-dialog.component.vue'
import carv from '../../assets/shape_avatar.svg'
export default {
    name: 'Customer',

    mixins: [utilities],

    components: {
        "area-dialog": areaDialog,
        "delete-confirm": confirmDialog
    },

    data() {
        return {
            customerHeaders: [
                { text: 'Id', value: 'code' },
                { text: 'Name', value: 'name' },
                // { text: 'Company Name', value: 'org_name' },
                { text: 'Mobile', value: 'phone' },
                { text: 'Address', value: 'address' },
            ],
            searchCustomer: '',
            carv: carv,
            customer: {
                id: null,
                code: '',
                name: '',
                father_name: '',
                phone: '',
                // email: '',
                nid: '',
                license_no: '',
                license_issued_by: '',
                license_issued_date: null,
                license_renual_date: null,
                license_verified_by: '',
                area_id: null,
                address: '',
                org_name: '',
                // org_phone: '',
                // credit_limit: 0,
                type: 'Retail'
            },
            selectedCustomer: {
                id: null,
                code: 'C0001',
                name: 'Select a Customer',
                org_name: 'Company Name',
                // credit_limit: 0,
                address: 'Customer\'s Address',
                mobile: '01XXXXXXXXX',
                // email: 'Customer\'s Email',
                // org_phone: 'Office Phone',
                area: 'Area',

            },
            area: null,
            customerId: null,
            waitingForSave: false,
            userType: '',

            frontImage: null,
            backImage: null,
            recieptImage: null,
            permissionImage: null,
            image5: null,
            image6: null,

            frontImagePreview: null,
            backImagePreview: null,
            recieptImagePreview: null,
            permissionImagePreview: null,
            image5Preview: null,
            image6Preview: null,

            downloadable: null,
            downloadableLink: null,
        }
    },

    watch: {
        area(area) {
            if (area == undefined) return;
            this.customer.area_id = area.id;
        }
    },

    async created() {
        this.customer.code = await this.$store.dispatch('customer/generateCustomerCode');
        await this.$store.dispatch('customer/getCustomers');

        let userData = JSON.parse(localStorage.getItem('userData'))
        this.userType = userData.userType;
    },

    methods: {
        onChangeImage5() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.image5Preview = '';
                this.image5 = null;
                return;
            }
            this.image5 = event.target.files[0];
            this.image5Preview = URL.createObjectURL(event.target.files[0]);
        },
        onChangeImage6() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.image6Preview = '';
                this.image6 = null;
                return;
            }
            this.image6 = event.target.files[0];
            this.image6Preview = URL.createObjectURL(event.target.files[0]);
        },
        onChangeFrontImage() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.frontImagePreview = '';
                this.frontImage = null;
                return;
            }
            this.frontImage = event.target.files[0];
            this.frontImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangeBackImage() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.backImagePreview = '';
                this.backImage = null;
                return;
            }
            this.backImage = event.target.files[0];
            this.backImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangeRecieptImage() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.recieptImagePreview = '';
                this.recieptImage = null;
                return;
            }
            this.recieptImage = event.target.files[0];
            this.recieptImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        onChangePermissionImage() {
            if (event.target.files == undefined || event.target.files.length < 1) {
                this.permissionImagePreview = '';
                this.permissionImage = null;
                return;
            }
            this.permissionImage = event.target.files[0];
            this.permissionImagePreview = URL.createObjectURL(event.target.files[0]);
        },

        showDetails(customer) {
            this.selectedCustomer = customer;
            this.selectedCustomer.front_img = `${this.$store.state.hostName}/${customer.front_img}`;
            this.selectedCustomer.back_img = `${this.$store.state.hostName}/${customer.back_img}`;
            this.selectedCustomer.reciept_img = `${this.$store.state.hostName}/${customer.reciept_img}`;
            this.selectedCustomer.permission_img = `${this.$store.state.hostName}/${customer.permission_img}`;
            this.selectedCustomer.image5 = `${this.$store.state.hostName}/${customer.image5}`;
            this.selectedCustomer.image6 = `${this.$store.state.hostName}/${customer.image6}`;
            this.image5Preview = `${customer.image5}`;
            this.image6Preview = `${customer.image6}`;
        },

        editCustomer(customer) {
            Object.keys(this.customer).forEach(key => {
                this.customer[key] = customer[key];
            })

            this.area = customer.area;
            this.customer.type = customer.type

            this.frontImagePreview = `${this.$store.state.hostName}/${customer.front_img}`;
            this.backImagePreview = `${this.$store.state.hostName}/${customer.back_img}`;
            this.recieptImagePreview = `${this.$store.state.hostName}/${customer.reciept_img}`;
            this.permissionImagePreview = `${this.$store.state.hostName}/${customer.permission_img}`;
            this.image5Preview = `${this.$store.state.hostName}/${customer.image5}`;
            this.image6Preview = `${this.$store.state.hostName}/${customer.image6}`;
        },

        async saveCustomer() {
            if (!this.validateCustomer()) {
                return;
            }

            this.waitingForSave = true

            let data = {
                customer: this.customer,
                frontImage: this.frontImage,
                backImage: this.backImage,
                recieptImage: this.recieptImage,
                permissionImage: this.permissionImage,
                image5: this.image5,
                image6: this.image6,
            }

            let success = await this.$store.dispatch('customer/saveCustomer', data);

            if (success) {
                this.resetForm();
            }

            this.waitingForSave = false
        },

        async deleteCustomer() {
            await this.$store.dispatch('customer/deleteCustomer', this.customerId);
            this.$refs.confirmDialog.dialog = false;
        },

        validateCustomer() {
            let isValid = true;

            this.$refs.customerForm.validate();

            this.$refs.customerForm.inputs.forEach(input => {
                if (input.hasError) isValid = false;
            })

            if (!isValid) return;

            // if(this.customer.area_id == null) {
            //     this.$store.dispatch('snackbar/error', 'Select a area');
            //     isValid = false; 
            //     return isValid;
            // }

            return isValid;
        },

        async resetForm() {
            Object.keys(this.customer).map(k => this.customer[k] = '');
            this.$refs.customerForm.resetValidation();
            this.customer.id = null;
            this.area = null;
            this.frontImagePreview 
                = this.backImagePreview 
                = this.recieptImagePreview 
                = this.permissionImagePreview 
                = this.image5Preview 
                = this.image6Preview
                = null;
            this.frontImage 
                = this.backImage 
                = this.recieptImage 
                = this.permissionImage
                = this.image5
                = this.image6
                = null;
            this.customer.code = await this.$store.dispatch('customer/generateCustomerCode');
        },
    }
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
    margin-top: 1px !important;
    height: 34px !important;
}

.carv_img_wrap {
    position: relative;
    padding-top: 84px;
}

.carv_img_wrap .carv_img {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    width: 144px;
    height: 62px;
    margin: 0 auto;
}

.uavatar {
    width: 64px;
    height: 64px;
    bottom: -32px;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.shadow_f {
    box-shadow: rgba(145, 158, 171, 0.16) 0px 4px 8px 0px !important;
}

// .info_list {
//     padding-top: 50px !important;
// }
.actions_btns {
    padding-top: 45px;
    text-align: center;
}

.doc-subtitle {
    color: #000;
    font-size: 13px;
}

.position-relative {
    position: relative;
}

.doc-image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}

.image-input {
    position: absolute;
    bottom: -15px;
    left: 40%;
}

::v-deep .v-icon.v-icon.v-icon--link {
    color: #000 !important;
}
</style>